/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import {
  Table, Button, Modal, Form, Input, Select,
} from 'antd'
// import { SearchOutlined } from '@ant-design/icons'

import FormGen from './forms/FormGen'

import apiService from '../services/apiService'

import {
  initState, ACTList, APTList, APTQuestions,
} from '../atoms/initAtom'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const EditForm = ({ data }) => {
  // console.log('data', data)
  const aptQ = useRecoilValue(APTQuestions)

  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    form.setFieldsValue({ ...data })
    setFormLock(false)
  }, [data])

  const onFinish = async (values) => {
    setFormLock(true)
    console.log(values)
    const res = await apiService.updateApproval(values)
    console.log('res', res)
  }

  const inputs = [
    {
      name: 'id',
      label: 'ID',
      placeholder: '0',
      type: 'input',
      disabled: true,
    },
    {
      name: 'apt_dep',
      label: 'Airport ICAO',
      placeholder: 'EFHK',
      type: 'input',
      disabled: true,
    },
    {
      name: 'act',
      label: 'Aircraft ICAO',
      placeholder: 'AT75',
      type: 'input',
      disabled: true,
    },
    {
      name: 'name',
      label: 'Approval name',
      placeholder: '',
      type: 'input',
    },
    {
      name: 'description',
      label: 'Description / Info',
      placeholder: '',
      type: 'input',
    },
  ]

  const b = [...inputs, ...aptQ]
  return (
    <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} disabled={formLock}>
      {b.map((z) => <FormGen {...z} key={`${z.name}_key`} />)}
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

const ListAll = ({ apt, act }) => {
  const [allAppr, setAllAppr] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editApproval, setEditApproval] = useState({})

  useEffect(() => {
    const i = async () => {
      try {
        const res = await apiService.getAllApprovalVersion(apt, act)
        console.log('getAllApprovalVersion', res)
        const tempData = res.map((v, index) => ({ ...v, key: index + 1 }))

        setAllAppr(tempData)
      } catch (error) {
        console.error(error)
      }
    }
    i()
  }, [])

  const showModal = (i) => {
    setEditApproval(i)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setEditApproval({})
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setEditApproval({})
    setIsModalVisible(false)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'modifiedAt',
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" key={`${record.id}_edit_button`} onClick={() => { showModal(record) }}>
          Edit
        </Button>
      ),
    },
  ]
  if (allAppr.length === 0) {
    return (
      <>Loading</>
    )
  }

  return (
    <>
      <Table rowkey="idACT" columns={columns} dataSource={allAppr} />
      <Modal
        title={`Edit aircraft ${editApproval.icao}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        {editApproval.id ? <EditForm data={editApproval} /> : <></>}
      </Modal>
    </>
  )
}

const tempApproval = {
  'act': '',
  'apt_dep': '',
  'apt_arr': null,
  'rwy': null,
  'cat': '',
  'jsonApproval': {
    'act': 'temp',
    'cat': '',
    'amts': [],
    'atts': [],
    'info': '',
    'rwys': [],
    'apt_dep': 'temp',
    'nickname': ''
  }
}


const NewDraft = ({ data, updateTable }) => {
  console.log(data)
  const apt = useRecoilValue(APTList)
  const act = useRecoilValue(ACTList)

  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    const i = async () => {
      form.setFieldsValue({})
    }
    i()
  }, [apt, act])

  const handleChange = (value) => {
    console.log(`selected ${value}`)
    // const h = apt.filter((f) = {})
  }

  const onFinish = async (values) => {
    try {
      setFormLock(true)
      console.log(values)

      const tempNew = Object.assign({}, tempApproval)
      //console.log(JSON.stringify(tempNew, null, 2))

      tempNew.act = data.act
      tempNew.apt_dep = data.apt_dep
      tempNew.jsonApproval.act = data.act
      tempNew.jsonApproval.apt_dep = data.apt_dep

      const aptData = apt.filter((obj) => obj.icao === data.apt_dep)

      // console.log(aptData)

      tempNew.jsonApproval.apt_dep_iata = aptData[0].iata
      tempNew.jsonApproval.apt_dep_name = aptData[0].name

      console.log('apt', apt)

      if (data.apt_arr) {
        tempNew.apt_arr = data.apt_arr
        tempNew.jsonApproval.apt_arr = data.apt_arr

        const aptData1 = apt.filter((obj) => obj.icao === data.apt_arr)

        // console.log(aptData)

        tempNew.jsonApproval.apt_arr_iata = aptData1[0].iata
        tempNew.jsonApproval.apt_arr_name = aptData1[0].name

        console.log('apt arr', apt)
      }

      // console.log(JSON.stringify(tempNew, null, 2))
      const res = await apiService.newApproval(tempNew)
      console.log('res', res)
      updateTable()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

const Clone = ({ data, handleCloneOk }) => {
  console.log(data)

  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  const onFinish = async (values) => {
    setFormLock(true)
    console.log(values)
    delete data.id
    delete data?.revokedAt
    delete data?.approvedAt
    delete data?.version
    delete data?.approvedBy
    delete data?.revokedBy
    delete data?.key
    console.log('Clone data:', data)
    const res = await apiService.newApproval(data)
    console.log('res', res)
    handleCloneOk()
  }

  return (
    <>
      <Form {...layout} form={form} name="control-hooks" disabled={formLock} onFinish={onFinish}>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Copy
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export { Clone, NewDraft }
