import axios from 'axios'

console.log(process.env)

const baseUrl = process.env.REACT_APP_API

const getToken = () => {
  const auth = localStorage.getItem('auth')
  const u = JSON.parse(auth)
  return u.authState.signInUserSession.idToken.jwtToken
}

const opt = (file) => {
  const t = getToken()
  if (file) {
    const options = {
      headers: {
        authorization: t,
        'Content-Type': 'text/plain'
      },
    }
    return options
  }
  const options = {
    headers: {
      authorization: t,
      'content-type': 'application/json'
    },
  }
  return options
}

const init = async () => {
  const response = await axios.get(`${baseUrl}init`, opt())
  return response.data
}

const getActiveApprovals = async (act) => {
  const response = await axios.get(`${baseUrl}approval/${act}`, opt())
  return response.data
}

const getActiveApprovalsRoute = async (act) => {
  const response = await axios.get(`${baseUrl}route/${act}`, opt())
  return response.data
}

const getApproval = async (aptId, actId) => {
  const response = await axios.get(`${baseUrl}approval/${actId}/${aptId}`, opt())
  return response.data
}

const getApprovalId = async (aptId, actId, id) => {
  const response = await axios.get(`${baseUrl}airport_approval/${aptId}/${actId}/${id}`, opt())
  return response.data
}

const getAllApprovalVersion = async (aptId, actId, arr) => {
  const url = `${baseUrl}approval/${actId}/${aptId}${arr ? '/' + arr : ''}/all`
  console.log(url)
  const response = await axios.get(url, opt())
  return response.data
}

const updateApproval = async (data) => {
  const response = await axios.put(`${baseUrl}approval/${data.act}/${data.apt_dep}/`, data, opt())
  return response.data
}

const getAllAirport = async () => {
  const response = await axios.get(`${baseUrl}airport`, opt())
  return response.data
}

const updateAirport = async (data) => {
  const response = await axios.put(`${baseUrl}airport/${data.icao}`, JSON.stringify(data), opt())
  return response.data
}

const addAircraft = async (data) => {
  const response = await axios.put(`${baseUrl}aircraft/${data.icao}`, data, opt())
  return response.data
}

const addAirport = async (data) => {
  const response = await axios.put(`${baseUrl}airport/${data.icao}`, data, opt())
  return response.data
}

const newApproval = async (data) => {
  const response = await axios.put(`${baseUrl}approval/${data.act}/${data.apt_dep}${data.apt_arr ? '/' + data.apt_arr : ''}`, data, opt())
  return response.data
}

const updateAircraft = async (data) => {
  const response = await axios.put(`${baseUrl}aircraft/${data.icao}`, data, opt())
  return response.data
}

const getAllAircraft = async () => {
  const response = await axios.get(`${baseUrl}aircraft`, opt())
  return response.data
}

const addFile = async (act, apt, body) => {
  // console.log('body', body)
  const response = await axios.put(`${baseUrl}attachment/${act}`, body, opt())
  return response.data
}

const getAllAttachment = async (act, apt) => {
  const response = await axios.get(`${baseUrl}attachment/${act}/${apt}`, opt())
  return response.data
}

const getAllAttachmentRwy = async (act, apt, rwy) => {
  const response = await axios.get(`${baseUrl}attachment/${act}/${apt}/${rwy}`, opt())
  return response.data
}

const getApprovalAttachment = async (act, apt, id) => {
  const response = await axios.get(`${baseUrl}attachment/${act}/${apt}/${id}`, opt())
  return response.data
}

const setAttachment = async (act, apt, id, body) => {
  const response = await axios.put(`${baseUrl}attachment/${act}/${apt}/${id}`, body, opt())
  return response.data
}

const getAttachment = async (id) => {
  const response = await axios.get(`${baseUrl}attachment/link/${id}`, opt())
  return response.data
}

const print = async (act, z, publish) => {
  const response = await axios.get(`${baseUrl}print/${act}/${z}/${publish ? 'true' : 'false'}`, opt())
  return response.data
}

const getPreArea = async (act) => {
  const response = await axios.get(`${baseUrl}chart/${act}`, opt())
  return response.data
}

const putPreArea = async (act, file) => {
  const response = await axios.put(`${baseUrl}chart/${act}`, file, opt(true))
  return response.data
}

const putFileS3 = async (url, file) => {
  const response = await axios.put(`${url}`, file, {
    headers: {
      'Content-Type': file.type,
    },
  })
  return response
}

export default {
  init,
  getApproval,
  getAllAirport,
  updateAirport,
  getAllAircraft,
  addAircraft,
  updateAircraft,
  getActiveApprovals,
  addAirport,
  newApproval,
  getAllApprovalVersion,
  updateApproval,
  addFile,
  putFileS3,
  getAllAttachment,
  getAttachment,
  getApprovalId,
  setAttachment,
  getApprovalAttachment,
  getAllAttachmentRwy,
  print,
  getActiveApprovalsRoute,
  getPreArea,
  putPreArea,
}
