const flatJson = (data) => {

  let jsonApproval

  // tarkistaa onko jsonApproval tekstiä vai objekti
  if (data?.jsonApproval?.act) {
    jsonApproval = data.jsonApproval
  } else {
    jsonApproval = JSON.parse(data.jsonApproval)
  }

  console.log('flatJson id:', data.id)

  const tempData = { ...data }
  delete tempData.jsonApproval

  const result = {
    ...tempData,
    ...jsonApproval
  }

  delete result.rwys
  delete result.amts
  // delete result.atts

  if (jsonApproval?.rwys) {
    result.rwys  = jsonApproval.rwys.map((v, index) => {
      delete v?.key
      return { key: index + 1, ...v }
    })
  }

  if (jsonApproval?.amts) {
    for (const element of jsonApproval.amts) {
      // console.log(element)
      result[element.spec_key] = element.value
    }
  }

  console.log('deflatted JSON:', JSON.stringify(result, null, 2))
  return result
}

const deflatJson = (data, q, attachments) => {
  // console.log('deflatJson data:', data)
  // console.log('deflatJson q', q)

  const res = {
    'id': data.id,
    'act': data.act,
    'apt_dep': data.apt_dep,
    'apt_arr': data.apt_arr,
    'rwys': data.rwys,
    'cat': data.cat,
    'info': data.info,
  }

  const tempJsonApproval = { ...data }
  delete tempJsonApproval.id

  const tempAmts = []
  for (const element of q) {
    const t = { spec_key: element.name, value: tempJsonApproval[element.name] }
    tempAmts.push(t)
    delete tempJsonApproval[element.name]
  }

  // console.log('tempAmts', tempAmts)

  tempJsonApproval.amts = tempAmts
  tempJsonApproval.atts = [...attachments]
  res.jsonApproval = { ...tempJsonApproval }

  return res
}

export { flatJson, deflatJson }