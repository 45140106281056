import { useEffect } from 'react'

import { useSetRecoilState } from 'recoil'
import RoutesApprovalsByFleet from './RoutesApprovalsByFleet'

import navState from '../atoms/NavState'

const ApprovalRoutes = () => {
  const setNavStateStatus = useSetRecoilState(navState)
  useEffect(() => {
    setNavStateStatus({ menu: 2, submenu: '' })
  }, [])
  console.log('routes')

  return (
    <RoutesApprovalsByFleet />
  )
}

export default ApprovalRoutes
