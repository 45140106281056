/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  Table, Input, Modal, Button, Typography, Space,
  Form, Select, Checkbox, Descriptions, message, Upload
} from 'antd'

import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'

const { Text } = Typography

// import FormGen from './components/forms/FormGen'
import NewApproval from './NewApproval'

import apiService from '../services/apiService'

import { flatJson } from '../services/approvalJson'

import notificationHandler from './notificationHandler'

// import navState from './atoms/NavState'
// import formState from './atoms/TestForm'
import { ACTList, APTList } from '../atoms/initAtom'
import navState from '../atoms/NavState'

const AdditionalInfo = ({ data }) => {
  const approval = flatJson(data)
  return (
    <Descriptions bordered column={1} size="small">
      <Descriptions.Item label="Info"><span style={{ whiteSpace: 'pre-line' }}>{approval?.info ? approval.info : ''}</span></Descriptions.Item>
      <Descriptions.Item label="Restrictions/Threats"><span style={{ whiteSpace: 'pre-line' }}>{approval?.['restrictions-threats-info'] ? approval['restrictions-threats-info'] : ''}</span></Descriptions.Item>

    </Descriptions>
  )
}

const New = ({ approved, act }) => {
  const apt = useRecoilValue(APTList)
  const navigate = useNavigate()


  const [selectAirport, setSelectAirport] = useState([])
  const [selectAircraft, setSelectAircraft] = useState([])

  const [form] = Form.useForm()
  const [formLock, setFormLock] = useState(false)

  useEffect(() => {
    const i = async () => {
      try {
        const y = apt.map((u) => ({ value: u.icao, label: `${u.icao} - ${u.name}` }))
        console.log('approved', approved)

        //const aptToRemove = approved.map(obj => obj.apt_dep)

        //const selectList = y.filter(obj => !aptToRemove.includes(obj.value))

        setSelectAirport(y)
        console.log('y', y)

      } catch (error) {
        console.error(error)
      }
      //form.setFieldsValue({  })
    }
    i()
  }, [apt, approved])

  const handleChange = (value) => {
    console.log(`selected ${value}`)
    // const h = apt.filter((f) = {})
  }

  const onFinish = async (values) => {
    setFormLock(true)
    // console.log(values)
    navigate(`/routes/${act}/${values.apt_dep}/${values.apt_arr}`)
  }

  return (
    <>
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          name="apt_dep"
          label="Airport 1 ICAO"
          rules={[{ required: true }]}

        >
          <Select
            onChange={handleChange}
            options={selectAirport}
            showSearch={true}
          />
        </Form.Item>
        <Form.Item
          name="apt_arr"
          label="Airport 2 ICAO"
          rules={[{ required: true }]}

        >
          <Select
            onChange={handleChange}
            options={selectAirport}
            showSearch={true}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Open
          </Button>
        </Form.Item>
      </Form>
    </>
  )

}

const ActiveApprovalsTable = ({ act }) => {
  const [sourceData, setSourceData] = useState([])
  const [data, setData] = useState([])
  const [checkedCat, setCheckedCat] = useState(['A', 'B', 'C'])
  // const act = useRecoilValue(ACTList)
  // const apt = useRecoilValue(APTList)
  const [value, setValue] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filterForm] = Form.useForm()

  // console.log('ACTlist', act)
  // console.log('APTlist', apt)

  const [printLoading, setPrintLoading] = useState(false)
  const [printModel, setPrintModel] = useState(false)
  const [printRes, setPrintRes] = useState({})
  const [publishRes, setPublishRes] = useState({})
  const [published, setPublished] = useState(false)

  const [preAnalyzedModel, setPreAnalyzedModel] = useState(false)

  const [fileList, setFileList] = useState([])

  const [imageBase64, setImageBase64] = useState(null)

  useEffect(() => {
    const i = async () => {
      try {
        filterForm.resetFields()
        setCheckedCat(['A', 'B', 'C'])
        setValue('')
        console.log(act)
        const res = await apiService.getActiveApprovalsRoute(act)
        console.log('getActiveApprovals', res)
        const routeList = res.filter(item => item['apt_arr'] !== null)
        //const n = res.map((t) => (t[0]))
        //console.log(n)
        const k = routeList.map((v, index) => ({ key: index + 1, ...v }))
        console.log(k)
        setData(k)
        setSourceData(k)
      } catch (error) {
        console.error(error)
      }
    }

    i()
  }, [act])

  // //////

  // /////

  const columns = [
    {
      title: 'Route',
      dataIndex: 'apt_dep',
      key: 'apt_dep',
      render: (text, d) => <Space direction="vertical">
        <Text>{d.jsonApproval.apt_dep_name}</Text>
        <Text strong>{text} / {d.jsonApproval.apt_dep_iata}</Text>
        <Text>{d.jsonApproval.apt_arr_name}</Text>
        <Text strong>{d.apt_arr} / {d.jsonApproval.apt_arr_iata}</Text>
      </Space>,
    },
    {
      title: 'Approved at',
      dataIndex: 'approvedAt',
      render: (text, d) => <Space direction="vertical">
        <Text>Version: {d.version}</Text>
        <Text>{format(new Date(text), 'dd.MM.yyyy')}</Text>
      </Space>,
    },
    {
      title: 'Category',
      dataIndex: 'cat',
      render: (text, d) => <p>{d.cat === 'A' ? 'Less complex' : ''}{d.cat === 'C' ? 'Complex' : ''}</p>,
    },
    {
      title: 'Actions',
      dataIndex: 'cat',
      render: (text, d) => <Link to={`/routes/${d.act}/${d.apt_dep}/${d.apt_arr}`}>View/Edit</Link>,
    }

  ]

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }


  const filterTable = (t, catFilter) => {
    console.log(t, catFilter)
    const filteredData = sourceData.filter(
      (entry) =>
        (entry.apt_dep.toLowerCase().includes(t.toLowerCase()) ||
          entry.jsonApproval.apt_dep_iata.toLowerCase().includes(t.toLowerCase())) &&
        (catFilter.includes(entry.cat))
    )
    console.log('filteredData', filteredData)
    setData(filteredData)
  }

  const filterHandler = (e, type) => {
    let airportF = `${value}`
    let catF = [...checkedCat]
    if (type === 'airport') {
      const currValue = e.target.value
      airportF = e.target.value
      setValue(currValue)
      console.log(value)
    }
    if (type === 'cat') {
      console.log(e)
      catF = e
      setCheckedCat([...catF])
    }


    filterTable(airportF, catF)
  }

  const printList = async () => {
    setPrintLoading(true)
    const res = await apiService.print(act, 'RTE')
    console.log(res)
    // Luo Blob PDF-datasta

    setPrintRes(res)

    setPrintLoading(false)
    setPrintModel(true)
  }

  const printListPublish = async () => {
    setPublished(true)
    setPrintLoading(true)
    try {
      const res = await apiService.print(act, 'RTE', true)
      console.log(res)
      // Luo Blob PDF-datasta
      setPrintRes(res)
      setPrintLoading(false)
      setPrintModel(true)
      notificationHandler('success', 'Publish done')
    } catch (error) {
      notificationHandler('error', 'Failed to publish')
    }

  }

  const openPreview = () => {
    const binaryString = window.atob(printRes.base64content)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const pdfBlob = new Blob([bytes], { type: 'application/pdf' })

    // Luo linkki Blob-objektiin
    const url = URL.createObjectURL(pdfBlob)

    // Avaa PDF uudessa välilehdessä
    window.open(url, '_blank')
  }

  const getPreAnalyzedArea = async () => {
    const res = await apiService.getPreArea(act)
    console.log(res)
    const binaryString = window.atob(res.link.image)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const pdfBlob = new Blob([bytes], { type: 'image/png' })

    // Luo linkki Blob-objektiin
    const url = URL.createObjectURL(pdfBlob)

    // Avaa PDF uudessa välilehdessä
    window.open(url, '_blank')
  }

  const handleChange = (info) => {
    console.log(info)
    const file = info.fileList[0].originFileObj
    console.log(file)
    const reader = new FileReader()

    reader.onload = () => {
      setImageBase64(reader.result.split(',')[1]) // Base64-muotoon muutettu kuva
      message.success(`${info.file.name} loaded successfully`)
    }
    console.log(reader.result)

    reader.onerror = () => {
      message.error('File reading failed')
    }

    reader.readAsDataURL(file)
  }

  // PUT-pyyntö kuvan lähettämiseksi
  const handleUpload = async () => {
    if (!imageBase64) {
      message.error('No image to upload')
      return
    }

    try {
      const response = await apiService.putPreArea(act, imageBase64)

      message.success('Image uploaded successfully')
    } catch (error) {
      message.error('Failed to upload image')
      console.error('Upload error:', error)
    }
  }
  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues)
  }
  const plainOptions = ['A', 'B', 'C']
  const options = [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ]

  return (
    <>
      <Form
        form={filterForm}
        layout="inline"
        style={{ marginBottom: '10px' }}
      >
        <Form.Item>
          <Text>Selected aircraft: </Text><Text strong>{act}</Text>
        </Form.Item>
        <Form.Item
          style={{ marginLeft: 'auto' }}
        >
          <Button style={{ marginRight: '8px' }} type="primary" onClick={() => setPreAnalyzedModel(true)} >
            Pre analyzed area
          </Button>
          <Button style={{ marginRight: '8px' }} type="primary" onClick={printList} loading={printLoading}>
            Preview and publish
          </Button>

          <Button type="primary" onClick={showModal} >
            New/View draft
          </Button>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        size="small"
        bordered
        pagination={{ pageSize: 15 }}
        rowClassName={(record, index) => record.cat === 'C' ? 'category-c-row' : record.cat === 'B' ? 'category-b-row' : null}
        expandable={{
          expandedRowRender: (record) => (
            <AdditionalInfo data={record} />
          ),
          rowExpandable: (record) => record.description !== 'Not Expandable',
        }}
      />
      <Modal
        title="Open unapproved"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
        maskClosable={false}
        width="35%"
        style={{ top: 25 }}
        footer={[
          <Button key="back" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <><New approved={sourceData} act={act} /></>
      </Modal>
      <Modal
        title="Printing"
        open={printModel}
        onCancel={() => { setPrintModel(false) }}
        maskClosable={false}
        width="500px"
        style={{ top: 25 }}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => { setPrintModel(false) }}>
            Close
          </Button>,
        ]}
      >
        <div>
          <Text>Preview created</Text>
          <div>
            <Button type='primary' key="preview" style={{ marginTop: '10px' }} onClick={openPreview}>
              Open print preview
            </Button>
          </div>
          {printRes.versionChange ?
            <div>
              <Space direction="vertical">
                <Text strong>Preview contains changes, do you want to publish?</Text>
              </Space>
              <div>
                <Button type="primary" onClick={printListPublish} loading={printLoading} disabled={published}>
                  Publish
                </Button>
              </div>
            </div>
            :
            <Space direction="vertical">
              <Text strong>No changes, publishing not possible</Text>
            </Space>}
        </div>
      </Modal>
      <Modal
        title="Pre Analyzed Area"
        open={preAnalyzedModel}
        onCancel={() => { setPreAnalyzedModel(false) }}
        maskClosable={false}
        width="500px"
        style={{ top: 25 }}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={() => { setPreAnalyzedModel(false) }}>
            Close
          </Button>,
        ]}
      >
        <div>
          <Button type="primary" onClick={getPreAnalyzedArea}>
            Open current
          </Button>
          <div style={{ marginTop: 50 }}>
            <div>
              <Upload
                beforeUpload={() => false} // Estetään automaattinen lataus
                onChange={handleChange}
                maxCount={1} // Vain yksi kuva sallitaan
              >
                <Button icon={<UploadOutlined />}>Select Image</Button>
              </Upload>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={!imageBase64} // Nappi ei ole käytettävissä, jos kuvaa ei ole valittu
                style={{ marginTop: 16 }}
              >
                Upload Image
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const RoutesApprovalsByFleet = () => {

  const setNavStateStatus = useSetRecoilState(navState)
  const act = useRecoilValue(ACTList)
  const [selectAircraft, setSelectAircraft] = useState([])
  const [selectedAct, setSelectedAct] = useState()

  useEffect(() => {
    setNavStateStatus({ menu: 2, submenu: '' })
    const actype = localStorage.getItem('selectedACType') || ['AT75']
    setSelectedAct(actype)
  }, [act])

  return (<>
    {selectedAct ? <ActiveApprovalsTable act={selectedAct} /> : <></>}
  </>)
}
export default RoutesApprovalsByFleet
