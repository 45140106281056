export const staticQ = [
  {
    name: 'id',
    label: 'ID',
    placeholder: '0',
    type: 'input',
    disabled: true,
  },
  {
    name: 'apt_dep',
    label: 'Airport 1 ICAO',
    placeholder: 'EFHK',
    type: 'input',
    disabled: true,
  },
  {
    name: 'apt_arr',
    label: 'Airport 2 ICAO',
    placeholder: 'EFHK',
    type: 'input',
    disabled: true,
  },
  {
    name: 'act',
    label: 'Aircraft ICAO',
    placeholder: 'AT75',
    type: 'input',
    disabled: true,
  },
  {
    name: 'info',
    label: 'Description/Info',
    placeholder: '',
    type: 'text',
  },
]